import {db} from './Database';
import {user} from './User';

const ONE_MINUTE_MS = 60 * 1000;

class SongRoom {
  constructor(songRoomName){
    this.name = songRoomName;
    this.roomConfigRef = db.ref(`/songrooms/${songRoomName}/config`);
    this.notesRef = db.ref(`/songrooms/${songRoomName}/notes`);
    this.config = {};
  // this.setConfig();
    this.getRoomConfig();
  }

  getRoomConfig(){
    this.roomConfigRef.once('value', async (snap)=> {
      this.config = await snap.val();
    });
  }

  onConfigChange(listenerCallback){
    this.roomConfigRef.on('value', async (snap)=> {
      const data = await snap.val();
      listenerCallback(data);
    });
  }

  // Assumes next quarter note in 4/4 time
  getNextBeatTimeMs(){
    if (!this.config) { throw new Error('SongRoom config not fetched yet'); }
    if (!user.timeOffset) { throw new Error('User time offset not set yet'); }
    let serverSongStartTime = new Date(1629126510000);

    let beatLengthMs = ONE_MINUTE_MS / this.config.bpm;
    document.getElementById('beat-length').innerHTML = beatLengthMs;

    let clientNow = Date.now();
    document.getElementById('client-now').innerHTML = clientNow;
    let serverTimeNow = clientNow + user.timeOffset;
    document.getElementById('server-now').innerHTML = serverTimeNow;

    let msSinceStart = serverTimeNow - serverSongStartTime;//this.config.startTime;
    let msUntilNextBeat = beatLengthMs - (msSinceStart % beatLengthMs);
    let nextBeatServerTime = serverTimeNow + msUntilNextBeat;

    document.getElementById('start-time').innerHTML = nextBeatServerTime;
    return nextBeatServerTime;  
  }

  // function getNextBeatTime(){
  //   let serverSongStartTime = new Date(1629126510000);
  //   let bpm = BPM;
  //   let oneMinute = 60 * 1000;
  //   let beatLength = oneMinute / bpm;
  //   let clientNow = Date.now();
  //   clientNowDiv.innerHTML = clientNow;
  //   let serverTimeNow = clientNow + offsetMs;
  //   serverNowDiv.innerHTML = serverTimeNow
  //   let msSinceSongStart = serverTimeNow - serverSongStartTime;
  //   let msUntilNextBeat = beatLength - (msSinceSongStart % beatLength);
    
  //   let nextBeatServerTime = serverTimeNow + msUntilNextBeat;
  //   let nextBeatTime = nextBeatServerTime;
  //   return nextBeatTime;  
  // }

  createUserDot(color){
    this.dotColor = color || 'blue';
    this.dotRef = db.ref(`/songrooms/${this.name}/dots/${user.uid}`);
    this.dotRef.set({x: .5, y: .5, color: color, uid: user.uid});
    this.dotRef.onDisconnect().remove();
  }

  setConfig(config){
    config = config || {
      bpm:120,
      startTime: Date.now(),
      scale: ["C2", "Eb2", "F2", "G2", "Bb2", "C3", "Eb3", "F3", "G3", "Bb3"],
      showParticipantDots: false,
      playDrumTrack: false
    }

    this.roomConfigRef.set(config);
  }

  broadcastNote(noteData){
    this.notesRef.child(`/${new Date().getTime()}`).set(noteData);
  }

  addNoteListener(listenerCallback){
    this.notesRef.orderByKey().limitToLast(1).startAt(new Date().getTime().toString()).on('child_added', async (snap)=>{
      let value = await snap.val();
      listenerCallback(value);
    })
  }

  setDotCoords = (xPercent,yPercent) => {
    if ((xPercent && xPercent > 0) && (yPercent && yPercent > 0)) {
      this.dotRef.set({
        uid: user.uid,
        x: xPercent,
        y: yPercent,
        color: this.dotColor
      })
    }
  }
}


export {SongRoom}