import {SongRoom} from '../database/SongRoom';
import {user} from '../database/User';
import * as Tone from 'tone';
import {throttle} from 'lodash';

import SampleLibrary from './Tonejs-Instruments'

class Note {
  constructor(noteData){
    this.note = noteData.note;
    this.duration = noteData.duration
    this.instrumentName = noteData.instrumentName;
    this.uid = noteData.uid;
  }
}

class Sequencer {
  constructor(){
    this.nextEightNotes = [];
    this.nextQuarterNotes = [];
    this.nextSixteenthNotes = [];

    this.started = false;
    this.songRoom = new SongRoom('beat');
    this.bpm = 60;
    this.playDrumTrack = false;

    this.note = "C3";
    this.duration = '4n';
    this.scale = ["C2", "D2", "E2", "F2", "G2", "A2", "B3", "C3", "D3", "E3", "F3", "G3", "A3", "B3"]
    // this.scale = ["C2", "Eb2", "F2", "G2", "Bb2", "C3", "Eb3", "F3", "G3", "Bb3"];
    // this.scale = ['C3', 'D3', 'E3', 'F3', 'G3', 'A3', 'B3','C4', 'D4', 'E4', 'F4', 'G4', 'A4', 'B4','C5', 'D5', 'E5', 'F5', 'G5', 'A5', 'B5'];
    // this.scale = ["A3", "B3", "C3", "D3", "E3", "F3", "G#3", "A4", "B4", "C4", "D4", "E4", "F4", "G#4"];
    this.durations = ['16n', '8n', '4n']
    window.Tone = Tone;

    this.instrumentOn = false;
    this.noteUICallback = () => {}

    if (document.location.pathname == '/viewer') {
      this.songRoom.addNoteListener(this.onNote.bind(this));
    }

    this.songRoom.onConfigChange(this.onConfigChange.bind(this));
  }

  setUserInstrument(instrumentName){
    if (!instrumentName) { return; }
    this.instrument = this.instruments[instrumentName];
    this.instrument.toMaster();
    this.instrumentName = instrumentName;
  }



  loadInstruments = async (instrument) => {
    return new Promise((resolve)=>{
      let instrumentsToLoad = instrument ? [instrument] : [
        // "piano",
        "xylophone",
        "violin", 
        'cello', 
        'contrabass', 
        // 'clarinet'
      ];
      let loadedInstrumentsCount = 0;

      this.instruments = SampleLibrary.load({
        instruments: instrumentsToLoad,
        baseUrl: "/instruments/",
        minify: true,
        ext: ".[ogg|mp3|wave]",
        onload: () => {
          loadedInstrumentsCount++;

          if (loadedInstrumentsCount == instrumentsToLoad.length) {
  
            resolve();
          }
        }
      });

      const reverb = new Tone.Reverb(0.8).toDestination();
      const vibrato = new Tone.Vibrato(5,0)

      for (let instrument in this.instruments) {
        this.instruments[instrument].chain(vibrato, reverb);
        this.instruments[instrument].toDestination();
      }
    });
  
  }

  start = async (clickEvent) => {  
    console.log('CLICK EVENT RECEIVED!');  
    // let startTimeSeconds = (this.songRoom.getNextBeatTimeMs() - Date.now())/1000;
    
    // if (startTimeSeconds < 0){
    //   startTimeSeconds = 0;
    // }

    // console.log(startTimeSeconds)
    await Tone.Transport.start();
    console.log('STARTED!')
    // document.getElementById('actual-start-time').innerHTML = Date.now()
    this.startNoteLoop();
    // this.startDrumLoop();
    this.started = true;  
    return;
  }

  startDrumLoop = () => {
    let kick = new Tone.MembraneSynth().toDestination();
    let snare = new Tone.NoiseSynth().toDestination();

    new Tone.Loop(time => {
      if (this.playDrumTrack){
        kick.triggerAttackRelease('C0', time); 
      }
    
    }, "4n").start(0);
  }

  addNoteUICallback(cb){
    this.noteUICallback = cb;
  }


  getInstrumentByName = (instrumentName) => {
    if (instrumentName in this.instruments){
      return this.instruments[instrumentName];
    } else {
      return this.instrument;
    }
  }

  // Mutates array directly
  playAndRemoveNotesFromArray(notesArray){
    for (let i = notesArray.length -1 ; i >= 0; i--){
      let note = notesArray[i];
      let instrument = this.getInstrumentByName(note.instrumentName);
      
      instrument.triggerAttackRelease(
        note.note,
        note.duration,
        Tone.now()
      );
      notesArray.splice(i, 1);
    }
  }

  playNextUserNoteFactory(durationType){
    return () => {
      if(this.nextNote && this.nextNote.note && durationType == this.nextNote.duration) {
        let instrument = this.getInstrumentByName(this.nextNote.instrumentName);
  
        this.songRoom.broadcastNote({
          note: this.nextNote.note,
          uid: user.uid,
          duration: durationType,
          instrumentName: this.nextNote.instrumentName,
          id: Math.random().toString(36).substring(2) + Date.now().toString(36)
        });

        if (this.playLocal){
          instrument.triggerAttackRelease(
            this.nextNote.note,
            this.nextNote.duration,
            Tone.now()
          );
        }

        if (!this.instrumentOn){
          this.nextNote = null;
        }
      }
    }

  }

  startNoteLoop = () => {
    console.log('NOTE LOOP STARTED')
    // PUBLISH NEW NOTE IMMEDIATELY OR WAIT?
    let playNextUser16thNote = this.playNextUserNoteFactory('16n');
    let playNextUser8thNote = this.playNextUserNoteFactory('8n');
    let playNextUser4thNote = this.playNextUserNoteFactory('4n');

    new Tone.Loop(time => {
      playNextUser16thNote()
      this.playAndRemoveNotesFromArray(this.nextSixteenthNotes);
    },"16n").start(0);


    new Tone.Loop(time => {
      playNextUser8thNote();
      this.playAndRemoveNotesFromArray(this.nextEightNotes);
    },"16n").start(0);

    new Tone.Loop(time => {
      playNextUser4thNote();
      this.playAndRemoveNotesFromArray(this.nextQuarterNotes);
      
    },"16n").start(0);
  }

  // createInstrument(){
  //   let synth = new Tone.Synth()
  //   let vibrato = new Tone.Vibrato(5, 0)
  //   let filter = new Tone.Filter(1000, 'lowpass', -48);
  //   filter.Q.value = 10; // DON'T SET THIS ABOVE 30
  //   let delay = new Tone.FeedbackDelay('16n', 0.3)
  //   // a compressor to keep levels in check
  //   let compressor = new Tone.Compressor(-30, 7);

  //   // const autoWah = new Tone.AutoWah(50, 6, -30).toDestination();
  //   // autoWah.Q.value = 5;

  //   this.shifter = new Tone.PitchShift(2).toMaster();
  //   this.shifter.wet.value = 1;
  //   this.shifter.pitch = 0;
   
  //   synth.chain(filter, compressor, this.shifter, Tone.Master)
  //   return synth;
  // }

  onConfigChange(config){
    this.scale = config.scale.filter((i)=> typeof(i) == "string")
    this.playDrumTrack = config.playDrumTrack;
    this.playLocal = config.playLocal;
    Tone.Transport.bpm.value = config.bpm || 60;
    if (this._configChangeListener){
      this._configChangeListener(config);
    }
  }

  addConfigChangeListener(cb){
    this._configChangeListener = cb;
  }

  removeConfigChangeListener(){
    this._configChangeListener = null;
  }

  findNextBeatTimestamp(startTime, bpm){
    let now = Date.now();
    let second = 1000;
    let minute = 60 * second;
    let beat = minute/bpm;
    let offset = (now - startTime) % beat;
    return now + offset;
  }

  getNextBeatDelta(){
    const now = Date.now();
    let nextBeat = now + (now % 1000) + 1000;
    return now - nextBeat
  }


  onNote(noteData){
    if (!this.started) { return; }

    let note = new Note(noteData);
    if (note.uid != user.uid) {
      if (note.duration === '4n') {
        this.nextQuarterNotes.push(note);
      } else if (note.duration == '8n') {
        this.nextEightNotes.push(note);
      } else if (note.duration == '16n') {
        this.nextSixteenthNotes.push(note);
      }

    }
  }

  setNoteByPercentage = (yPercent, xPercent) => {
    
    if (yPercent <= 0 || xPercent <= 0) { 
      return; 
    }

    let note = new Note({
      note: this.scale[Math.floor(this.scale.length * (1 - yPercent))],
      duration: this.durations[Math.floor(this.durations.length * (1 - xPercent))],
      instrumentName: this.instrumentName
    });

    this.nextNote = note;
  }
}

const sequencer = new Sequencer();
window.sequencer = sequencer;
export {sequencer, Sequencer}