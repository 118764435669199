import React from 'react';
import bluebg from '../img/blue29.jpg';
import _ from 'lodash';


var yellowbg = 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg'



var COLORS = {
  'blue': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    backgroundOffset: '150px 50px',
    hueRotate: 168,
    invertHueRotate: 200
  },
  'purple': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    backgroundOffset: '300px 20px',
    hueRotate: 212,
    invertHueRotate: 200
  },
  'yellow': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    hueRotate: 2,
    invertHueRotate: 30,
    backgroundOffset: '50px 300px',

  },
  'magenta': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    hueRotate: 290,
    invertHueRotate: 290,
    backgroundOffset: '200px 300px',
  },
  'orange': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    hueRotate: -30,
    invertHueRotate: 190,
    backgroundOffset: '200px 300px',
  },
  'orange': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    hueRotate: -30,
    invertHueRotate: 190,
    backgroundOffset: '200px 300px',
  },
  'darkblue': {
    backgroundUrl: 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg',
    hueRotate: 190,
    invertHueRotate: 170,
    backgroundOffset: '200px 300px',
  },
  'loading': {
    backgroundUrl: '/yellow-bg-small.png',
    hueRotate: -30,
  }
};

export default class Dot extends React.Component {
  static async preloadImages(){
    let imageUrl = 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg';

    return new Promise((resolve)=>{

      let image = document.createElement('img');
      image.src = imageUrl;
      image.style.display = 'none';
      document.getElementsByTagName('body')[0].append(image);
      image.onload = () => {
        resolve();
      }
    });

  }
  
  constructor(props){
    super(props);
    this.state = {
      handdrawnClass: 'handdrawn1',
      rotation: 0,
      bgX: 0,
      bgY: 0,
    }

    this.handdrawnClasses = ['handdrawn1', 'handdrawn2', 'handdrawn3'];
  }

  shouldComponentUpdate(nextProps, nextState){
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state);
  }

  componentDidMount(){
    // console.log('MOUNTED')
    if (this.props.color == 'loading') {
      this.tick();
    }
  }
  // TODO: SYNCRONIZE TO THE BEAT
  componentWillReceiveProps(nextProps){
    this.tickOnce();
  }


  getRandomHanddrawnClass(){
    return this.handdrawnClasses[Math.floor(Math.random()*this.handdrawnClasses.length)];
  }

  getRandomRotation(){
    return Math.random() * 360
  }

  getRandomBgOffset(){
    return (Math.random() * 600) - 100
  }

  tickOnce(){
    this.setState({
      handdrawnClass: this.getRandomHanddrawnClass(),
      rotation: this.getRandomRotation(),
      bgX: this.getRandomBgOffset(),
      bgY: this.getRandomBgOffset()
    });
  }

  tick(){
    this.tickOnce();

    setTimeout(this.tick.bind(this), 100);
  }

  render(){
    let hueDegrees = (this.props.huePercent || 0) * 360;

    let color = COLORS[this.props.color];
    return (
      <div 
        style={{
          position: 'absolute', 
          top: `${(this.props.yPercent * 100)}%`,
          left: `${this.props.xPercent * 100}%`
        }}
      >
        <div className="disable-select" style={{
          filter: `hue-rotate(${this.props.invertColor ? color.invertHueRotate: color.hueRotate}deg)`
        }}>
          
          <div style={{
            filter: `url('#${this.state.handdrawnClass}')`,
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            transform: `rotate(${this.state.rotation}deg)`,
            background: `url(${color.backgroundUrl}) 400px 400px`,
            backgroundPosition: color.backgroundPosition,
            backgroundSize: this.props.color == 'loading' ? 'cover': 'initial',
            border: '3px solid white',
            // opacity: 0.7
          }}>

          </div>
        </div>
      </div>
    )
  }
}