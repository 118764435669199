import firebase from "firebase/app";
import 'firebase/database';
import _ from 'lodash';



class Database {
  constructor(){
    var firebaseConfig = {
      apiKey: "AIzaSyCLedWzriy3ZUJHImccHH87WGPhFJ9IhXs",
      authDomain: "touchsynth.firebaseapp.com",
      databaseURL: "https://touchsynth-default-rtdb.firebaseio.com",
      projectId: "touchsynth",
      storageBucket: "touchsynth.appspot.com",
      messagingSenderId: "164663005831",
      appId: "1:164663005831:web:1eb40e6630195b52195e22"
    };

    firebase.initializeApp(firebaseConfig);

    _.extend(this, firebase.database());

  }
}

const db = new Database();
window.db = db;

export {db, Database}