import React from 'react';
import '../App.css';
import Dot from '../components/Dot';
import {throttle} from 'lodash';
import * as Tone from 'tone'
import {sequencer} from '../audio/Sequencer';
import {db} from '../database/Database';
import {user} from '../database/User';

var bg = 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg'
// var bg = '/watercolor-paper.png';

class Viewer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      dots: []
    }
    this.addDotListener();
  }

  addDotListener(){
    // TODO: MOVE THIS OUT? MAKE SONG ROOM NAME DYNAMIC
    this.dotsRef =  db.ref(`/songrooms/beat/dots`);

    this.dotsRef.once('value', async (snap)=>{
      let data = await snap.val();
      this.setDotsStateFromFirebaseData(data);
    });

    this.dotsRef.on('value', async (snap)=>{
      let data = await snap.val();
      this.onDotMove.bind(this)(data)
    });
  }

  setDotsStateFromFirebaseData(data){
    // window.dots = data || {};

    // for (let dot in Object.keys(window.dots)){
    //   if 

    // }


    if (data == null || data == undefined) {
      
      this.setState({dots: []});
    } else {
      this.setState({dots: Object.values(data)});
    }

    
  }

  onDotMove = throttle((data)=>{
    this.setDotsStateFromFirebaseData(data);
  }, 150)


  async onMouseDown(event){
    if (!sequencer.started){
      await sequencer.start(event);
    }
  }

  renderDot(){

  }

  renderDots(){}

  componentDidMount(){
    window.dotDivs = {};
    let loader = async () => {
      // TODO: DO THIS IN PARALLEL?
      // await user.getServerTimeOffset();
      await sequencer.loadInstruments();
      await Dot.preloadImages();
      this.setState({isLoaded: true});
    }

    loader.bind(this)();
    this.addTouchListeners();
  }

  addTouchListeners(){
    var isTouchDevice = 'ontouchstart' in document.documentElement;
    if (isTouchDevice) {
      window.addEventListener('touchstart', this.onMouseDown.bind(this), false);
    
    } else {
      window.addEventListener('mousedown', this.onMouseDown.bind(this), false);
    }

  }



  render(){
    return (
      <div 
        style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        className: 'no-touch'
      }}>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: '0',
            background: `url(${bg}) 150px 0px`,
            textAlign: 'center',
            filter: 'hue-rotate(-30deg)'
          }}
        />
       

       {
         this.state.dots && this.state.dots.map((dot)=>{
           return (
            <div 
            style={{
              position: 'absolute', 
              top: `${(dot.y * 100)}%`,
              left: `${dot.x * 100}%`
            }}>
              <Dot
                color={dot.color}
                // xPercent={this.getPercentFromSide(this.state.x)}
                // yPercent={this.getPercentFromTop(this.state.y)}
              />
            </div>
           )
         })
       }
      </div>
    );
  }
}

export default Viewer;
