import {db} from './Database';
import firebase from "firebase/app";

const TIMES_TO_SYNC_SERVER_TIME = 10;

class User {
  constructor(){
    this.uid = Math.random().toString(36).substring(2) + Date.now().toString(36);
    this.userRef = db.ref(`/users/${this.uid}`);
    this.timeOffset = 0;
  }

  /*
    Since the each client's local time will be different than the time on the server
    we need to calculate the "clock drift" from the server time.

    We compute this by saving the user's local time + the server's time, comparing the delta,
    and taking the average of those deltas.
  */
  async getServerTimeOffset(){
    console.log('COMPUTING SERVER TIME OFFSET...');

    let syncCount = 0;
    let timeSyncRef = this.userRef.child('/timeSync');

    return new Promise((resolve)=>{

      let addTimeDiff = async () => {
        if (syncCount < TIMES_TO_SYNC_SERVER_TIME){
          await timeSyncRef.push({
            clientTime: Date.now(),
            serverTime: firebase.database.ServerValue.TIMESTAMP
          });
          syncCount++;
          addTimeDiff();
        } else {
          calcTimeOffset();
        }
      };
  
      let calcTimeOffset = async () => {
        timeSyncRef.once('value', async (snap)=> {
          let times = snap.val();
          let offsets = Object.values(times).map((time)=>{
            return time.serverTime - time.clientTime
          });
          
          let averageOffset = offsets.reduce((a, b) => a + b) / offsets.length;
          this.timeOffset = averageOffset;
          console.log('SERVER TIME OFFSET:', averageOffset);
          document.getElementById('offset').innerHTML = averageOffset;
          resolve(averageOffset);
        });
      }

      addTimeDiff();
    });
  }

  
  async syncClockWithFirebaseTime(){
    return new Promise((resolve)=>{
      document.getElementById('offset').innerHTML = 'Syncing to server time';
      var offsetRef = firebase.database().ref(".info/serverTimeOffset");
      offsetRef.on("value", async (snap) => {
        var offset = await snap.val();
        var estimatedServerTimeMs = new Date().getTime() + offset;
        this.timeOffset = offset;
        document.getElementById('offset').innerHTML = this.timeOffset;
        resolve();
      });
    });
  
  }
}

let user = new User();

export {user, User};