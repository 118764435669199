import React from 'react';
import '../App.css';
import Dot from '../components/Dot';
import {throttle, debounce} from 'lodash';
import * as Tone from 'tone'
import {sequencer} from '../audio/Sequencer';
import {db} from '../database/Database';
import {user} from '../database/User';
import _ from 'lodash';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import pineapple from '../img/wave.svg';

const url = "//pindarlabs.us5.list-manage.com/subscribe/post?u=e0368efb1452f2428c8022541&amp;id=c0ee1078ed";

const EmailForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div
      style={{

      }}
    >
      {status === "sending" && <div style={{ color: "blue" }}>...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
  
      <br />
      <input
        style={{width: 'calc(100% - 20px)', marginBottom: '10px', marginTop: '-10px', border: '2px solid #222', fontFamily: 'Helvetica', fontSize: '18px', padding: '14px 10px'}}
        ref={node => (email = node)}
        type="email"
        placeholder="Email"
      />
      <br />
      <button style={{ width: '100%', border: 0, fontWeight: 600, backgroundColor: '#222', padding: '14px 10px', fontFamily: 'Helvetica', color: '#fff', fontSize: '20px'}} onClick={submit}>
        SEND ME ART
      </button>
    </div>
  );
};

var bg = 'https://raw.githubusercontent.com/stamen/watercolor/master/assets/textures/watercolor/yellow_orange_12.jpg'
// var bg = '/watercolor-paper.png';

const COLOR_TO_INSTRUMENT = {
  'blue': 'cello', //
  'purple': 'xylophone',
  'yellow': 'violin', //
  'magenta': 'contrabass',
  // 'orange': 'clarinet',
  // 'darkblue': 'contrabass'
}

class Participant extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      mouseDown: false,
      x: window.innerWidth/2,
      y: window.innerHeight/2,
      xPercent: 0,
      yPercent: 0,
      invertDot: false,
      dots: [],
      color: props.color,
      uiState: 'loading', // loading, loaded, starting, started
      showAboutModal: false
    }

    sequencer.addNoteUICallback(()=>{
      this.setState({invertDot:true});
      setTimeout(()=>{
        this.setState({invertDot:false});
      }, 100)
    });

    const urlParams = new URLSearchParams(window.location.search);
    this.isViewer = urlParams.get('viewer');

  
    if (this.isViewer){
      this.dotsRef =  db.ref(`/songrooms/beat/dots`);
      this.dotsRef.on('value', async (snap)=>{
        let data = await snap.val();
        this.onDotMove.bind(this)(data)
      })
    }

    this.dotsRef =  db.ref(`/songrooms/beat/dots`);

    // this.addDotListener();
  }

  addDotListener(){
    // TODO: MOVE THIS OUT? MAKE SONG ROOM NAME DYNAMIC

    this.dotsRef.once('value', async (snap)=>{
      let data = await snap.val();
      this.setDotsStateFromFirebaseData(data);
    });

    this.dotsRef.on('value', async (snap)=>{
      let data = await snap.val();
      this.onDotMove.bind(this)(data)
    });
  }

  removeDotListener(){
    this.dotsRef.off();
    this.setState({dots:[]});
  }

  setDotsStateFromFirebaseData(data){
    if (data == null || data == undefined) {
      console.log('NULL DATA')
      this.setState({dots: []});
    } else {
      let newData = this.dotsWithoutCurrentUser(Object.values(data));
      console.log(newData, this.state.dots);
      console.log('SAME?', _.isEqual(newData,this.state.dots));
      this.setState({dots: this.dotsWithoutCurrentUser(Object.values(data))});
    }
  }

  dotsWithoutCurrentUser(dots){
    return dots.filter((dot)=>{
      return dot.uid != user.uid;
    });
  }

  onDotMove = debounce((data)=>{
    this.setState({dots: this.dotsWithoutCurrentUser(Object.values(data))});
  }, 50)


  async onMouseDown(event){
    if (this.state.showAboutModal){ return}

    console.log('ON MOUSE DOWN!')
    // TODO: SWITCH TO ASYNC?
    if (!sequencer.started){
      await sequencer.start(event);
    }
    
    this.mouseDown = true;
    sequencer.instrumentOn = true;

    let y = event.pageY || (event.touches && event.touches[0].pageY);
    let x = event.pageX || (event.touches && event.touches[0].pageX);
    let yPercent = this.getPercentFromTop(y);
    let xPerfect = this.getPercentFromSide(x);
    sequencer.setNoteByPercentage(yPercent, xPerfect);

    this.setState({
      mouseDown: true,
      uiState: 'started',
      x: x,
      y: y,
      xPerfect,
      yPercent
    });

    this.throttledDotMove(x, y);

  }

  onMouseUp(e){
    this.mouseDown = false;
    sequencer.instrumentOn = false;
    this.setState({mouseDown: false});
  }

  getPercentFromTop(y){
    return ((y-60)/window.innerHeight);
  }

  getPercentFromSide(x){
    return ((x-60)/window.innerWidth);
  }


  componentDidMount(){
    let loader = async () => {
      // TODO: DO THIS IN PARALLEL?
      await user.getServerTimeOffset();
      await sequencer.loadInstruments(COLOR_TO_INSTRUMENT[this.state.color]);
      sequencer.setUserInstrument(COLOR_TO_INSTRUMENT[this.state.color]);
      await Dot.preloadImages();
    
      this.setState({uiState: 'loaded'});
    }

    loader.bind(this)();
    sequencer.songRoom.createUserDot(this.state.color);
    // setTimeout(()=>{
      // sequencer.setUserInstrument(COLOR_TO_INSTRUMENT[this.state.color]);
    // }, 200)

    this.throttledDotMove = throttle((x,y) => {
      this.setState({x, y});

      sequencer.songRoom.setDotCoords(
        this.getPercentFromSide(x),
        this.getPercentFromTop(y)
      )
    }, 50);

    this.onMouseMove = (event) => {
      if (this.mouseDown) {
        let y = event.pageY || (event.touches && event.touches[0].pageY);
        let x = event.pageX || (event.touches && event.touches[0].pageX);
        let yPercent = this.getPercentFromTop(y);
        let xPerfect = this.getPercentFromSide(x);
        sequencer.setNoteByPercentage(yPercent, xPerfect);
  
        this.throttledDotMove(x,y);
      }
    }

    this.addTouchListeners();

    // this.addDotListener();

    sequencer.addConfigChangeListener(this.onConfigChange.bind(this));
  }

  onConfigChange(config){
    if (config.showParticipantDots){
      this.addDotListener();
    } else {
      this.removeDotListener();
    }
  }

  addTouchListeners(){
    var isTouchDevice = 'ontouchstart' in document.documentElement;
    console.log('IS TOUCH DEVICE?', isTouchDevice)

    document.addEventListener('touchend', (e)=>{
      if (Tone.context.state != 'running'){
        Tone.start()
      }
    });
    // document.onclick = () => {
    //   console.log('ON CLICK!')
    // }
    
    if (isTouchDevice) {

      document.addEventListener('touchstart', this.onMouseDown.bind(this), false);
      document.addEventListener('touchend', this.onMouseUp.bind(this), false);
      document.addEventListener('touchmove', this.onMouseMove.bind(this), { passive: false });

    } else {
      window.addEventListener('mousedown', this.onMouseDown.bind(this), false);
      window.addEventListener('mouseup', this.onMouseUp.bind(this), false);
      window.addEventListener('mousemove', this.onMouseMove.bind(this), { passive: false });

    }

  }

  renderAboutModal(){
    return (
      <div
        style={{backgroundColor: '#fff', width: '100%', height: '100%', position: 'fixed', top:0, left:0, zIndex: 100, color: '#222222', fontFamily: 'Helvetica'}}
      >
        <div style={{
          color: '#222',
          fontFamily: 'Helvetica',
          fontWeight: 300,
          fontSize: '32px',
          position: 'fixed',
          top: '4px',
          right: '0px',
          zIndex: 11,
          width: '50px',
          height: '50px',
          textAlign: 'center'
        }}
          onClick={(e)=>{
            e.preventDefault();
            this.setState({showAboutModal: false})
          }}
        >x</div>
        <div style={{width: 'calc(100%-40px)', padding: '20px', textAlign:'left'}}>
          <h1 style={{fontWeight: 600, fontSize: '84px', letterSpacing:'-2px', lineHeight: '30px'}}>HELLO</h1>
          <p style={{fontSize: '28px', fontWeight:300}}>I'm Matt. I make interactive art you can play with.</p>
          <p style={{fontSize: '28px', fontWeight:300}}>You can see future art on <a href="https://www.instagram.com/pindarlabs" target="_blank">@pindarlabs</a> or via email.</p>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <EmailForm
                status={status}
                message={message}
                onValidated={formData => { console.log(formData);subscribe(formData)}}
              
              />
            )}
          />
          <p style={{fontSize: '28px', fontWeight:300}}>Or say hello at <a href="mail:mattthered@gmail.com">MattTheRed@gmail.com</a>.</p>
        </div>
      

      </div>
    )
  }

  render(){
    return (
      <div 
        style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: '0',
          className: 'no-touch'
      }}>
        {!this.state.showAboutModal && <img src={pineapple} style={{position: 'fixed', bottom: '6px', right: '0px', zIndex: 8, width: '60px'}} onClick={(e)=>{ e.preventDefault(); this.setState({showAboutModal: true})}}/>}
        {this.state.showAboutModal && this.renderAboutModal()}
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: '0',
          
            background: `url(${this.state.uiState == 'started' ? bg: ''}) 150px 0px`,
            textAlign: 'center',
            filter: 'hue-rotate(-30deg)'
          }}
        />
         { this.state.uiState == 'loading' && <div 
            style={{
              textAlign: 'center'
            }}
            // style={{
            //   position: 'relative', 
            //   top: `${(window.innerHeight/2)- 60}px`,
            //   left: `${(window.innerWidth/2) - 60}px`
            // }}
          
          >
              <Dot
                color="loading"
                xPercent={this.getPercentFromSide(this.state.x)}
                yPercent={this.getPercentFromTop(this.state.y)}
              />
                <div style={{
                  position: 'absolute',
                  top:'calc(100vh/2 + 100px)',
                  color: '#222',
                  fontWeight: 100,
                  fontSize: '20px',
                  textTransform: 'uppercase',
                  fontFamily: 'Helvetica',
                  letterSpacing: '1px',
                  width: '100%',
                  textAlign: 'center'
                }}>
                  Loading...
                </div>
          
              </div>
            }
                

          { this.state.uiState == 'loaded' && 
            <div style={{
                  position: 'fixed',
                  top:'80px',
                  width: '100%',
                  left: 0,
                  color: '#222',
                  fontWeight: 100,
                  fontSize: '20px',
                  textTransform: 'uppercase',
                  fontFamily: 'Helvetica',
                  letterSpacing: '1px',
                  textAlign: 'center',
                  lineHeight: '150%',
                  fontSize: '25px'
                }}
  
              >
                  Move the dot<br/> to make music
     
            </div>
            
          }
      

        {
          this.state.uiState == 'started' && this.state.dots && this.state.dots.map((dot)=>{
            return (
              <Dot color={dot.color} xPercent={dot.x} yPercent={(dot.y)}/>
            )
          })
        }
          { (this.state.uiState == 'started' || this.state.uiState == 'loaded' )&& 
              <Dot
                color={this.props.color}
                invertColor={this.state.invertDot}
                xPercent={this.getPercentFromSide(this.state.x)}
                yPercent={this.getPercentFromTop(this.state.y)}
              />
              }
      </div>
  
    );
  }
}

export default Participant;
