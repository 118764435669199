import React from "react";
import Participant from './pages/Participant';
import Viewer from './pages/Viewer';
import Dot from './components/Dot';
import {sequencer} from './audio/Sequencer';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props){
    super(props);

    if (document.location.search.indexOf('debug=true') > -1){
      document.getElementById('debug').style.display = 'block';
    }

    this.state = {
      isLoaded: true
    }
    // this.state = {
    //   isLoaded: false,
    //   x: window.innerWidth/2,
    //   y: window.innerHeight/2,
    // };
  }

  // componentDidMount(){
  //   let loader = async () => {
  //     // TODO: DO THIS IN PARALLEL?
  //     await sequencer.loadInstruments();
  //     await Dot.preloadImages();
  //     this.setState({isLoaded: true});
  //   }

  //   loader.bind(this)();

  // }

  render(){
    return (
      <div>
        { !this.state.isLoaded && <div 
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            textAlign: 'center',
            color: '#222',
            fontWeight: 100,
            fontSize: '20px',
            textTransform: 'uppercase',
            fontFamily: 'Helvetica',
            letterSpacing: '1px'
          }}
        
        >
          <div style={{
            position: 'relative', 
            top: `${this.state.y - 60}px`,
            left: `${this.state.x - 60}px`
            // animationName: 'spin',
            // animationDuration: '2000ms',
            // animationIterationCount: 'infinite',
            // animationTimingFunction: 'linear'
          }}>
            <Dot color="loading"/>
          </div>
          <div style={{
            position: 'relative',
            top: `${this.state.y - 30}px`,
          }}>
            Loading...
            </div>
          
          </div>}
        { 
          this.state.isLoaded && 
          <Router>
            <Switch>
              <Route path="/viewer">
                <Viewer />
              </Route>
              <Route path="/dots/:color" render={(props)=>
                <Participant color={props.match.params.color}/>
              }>
              </Route>
              <Redirect from="/yllw" to="/dots/yellow" />
              <Redirect from="/" to="/dots/blue" />
            </Switch>
          </Router>
        }
      
      </div>
    );
  }
  
}
